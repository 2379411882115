/** Import Starts Here ... */
import React, { Component, Fragment, useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route, useParams, useHistory } from "react-router-dom";
import 'react-rangeslider/lib/index.css';
import classnames from 'classnames';
import Header from "../../common/Header/Header";
import { useForm } from "react-hook-form";
import eyeSlash from '../../assets/images/eye-slash.svg';
import eye from '../../assets/images/eye.svg';
import axios from "axios";
import { toast } from 'react-toastify';
import helper from "../../helper/axiosHelper";
import { enableLoader } from "../../Action/userAction";
import { useDispatch } from "react-redux";

/** Function Component Starts Here ... */
const Home = (props) => {

  /** State Initialization. */
  const { register, handleSubmit, formState: { errors }, reset, trigger, watch } = useForm();
  const [changePwd, setChangePwd] = useState({ pwdView: true, confirmPwdView: true })
  const dispatch = useDispatch();
  const history = useHistory();
  let id = useParams();
  id = id.reset_code;

  const [verificationStatus, setVerificationStatus] = useState(false)
  let baseUrl = helper.forgetVerifyUrl();
  let baseURL = helper.resetUrl();

  /** useEffect Function to verify an url is authenticated or not by using this API. */
  useEffect(() => {
    dispatch(enableLoader(true));
    axios.get(`${baseUrl}/${id}`).then((res) => {
      if (res.data.status == true) {
        dispatch(enableLoader(false));
        setVerificationStatus(true);
      } else {
        dispatch(enableLoader(false));
        setVerificationStatus(false);
        toast.error(res.data.message)
      }
    }).catch((err) => {
      dispatch(enableLoader(false));
      toast.error(err.data.message)
    })
  }, [])

  /** Function to submit when the reset details are stored on DB by using this API
   * is used for further use while login again with the same credentials.
   * @return a response based on API response.
   */
  const onSubmit = (e) => {
    dispatch(enableLoader(true));
    let obj = {
      password: e.password,
      confirmPassword: e.confirmPassword,
      forget_code: id,
    }

    axios.post(`${baseURL}`, obj).then((response) => {
      if (response.data.status === true) {
        dispatch(enableLoader(false));
        toast.success(response.data.message);
        reset();
        history.push('/login')
      } else {
        dispatch(enableLoader(false));
        toast.error(response.data.message);
      }
    });
  }

  return (
    <Fragment>
      <Header />
      <div className="crPg">
        <div className="container-fluid">
          <div className="crPgCon">
            <div className="crPgHd v2">
              <h1>Reset Password</h1>
            </div>
            {
              verificationStatus === true
                ?
                <div className="crPgBd">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="crPgFrm">
                      <div className="form-group">
                        <label for="">New Password<span className="txRd">*</span></label>
                        <div className="input-group">
                          <input type={changePwd.pwdView === false ? 'text' : 'password'} className="form-control"
                            autoComplete="new-password"
                            placeholder="Enter your password"
                            name="password"
                            {...register('password', {
                              required: 'Password is required',
                              pattern: {
                                value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                message: 'Password is Invalid'
                              },
                            })}
                            onKeyUp={() => {
                              trigger('password')
                            }}
                            onMouseOut={() => {
                              trigger('password')
                            }} />
                          <div className="input-group-append">
                            <span className="btn" onClick={(e) => {
                              e.stopPropagation();
                              setChangePwd({ ...changePwd, pwdView: !(changePwd.pwdView) })
                            }}>
                              {
                                changePwd.pwdView ?
                                  <img src={eyeSlash} alt="eye-slash" />
                                  :
                                  <img src={eye} alt="eye-slash" />
                              }
                            </span>
                          </div>
                        </div>
                        {errors.password && (
                          <div className="form-error" style={{ color: "red" }}>
                            {errors.password.message}
                          </div>
                        )}
                      </div>


                      <div className="form-group">
                        <label for="">Confirm Password<span className="txRd">*</span></label>
                        <div className="input-group">
                          <input type={changePwd.confirmPwdView === false ? 'text' : 'password'} className="form-control" placeholder="Re - Enter your password"
                            autoComplete="new-password"
                            name="confirmPassword"
                            {...register('confirmPassword', {
                              required: 'Confirm Password is required',
                              pattern: {
                                value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                message: 'Confirm Password is In Valid'
                              },
                              validate: (value) => value === watch('password') || "Password's didn't Match"
                            })}
                            onKeyUp={() => {
                              trigger('confirmPassword')
                            }}
                            onMouseOut={() => {
                              trigger('confirmPassword')
                            }} />
                          <div className="input-group-append">
                            <span className="btn" onClick={(e) => {
                              e.stopPropagation();
                              setChangePwd({ ...changePwd, confirmPwdView: !(changePwd.confirmPwdView) })
                            }}>
                              {
                                changePwd.confirmPwdView ?
                                  <img src={eyeSlash} alt="eye-slash" />
                                  :
                                  <img src={eye} alt="eye-slash" />
                              }
                            </span>
                          </div>
                        </div>
                        {errors.confirmPassword && (
                          <div className="form-error" style={{ color: "red" }}>
                            {errors.confirmPassword.message}
                          </div>
                        )}
                      </div>



                      <div className="frmSbm">
                        <button className="btn btn-block btn-pup btn-h49">
                          Reset Password
                        </button>
                      </div>

                    </div>
                  </form>
                </div>
                :
                <div className="crPgHd">
                  <h4 style={{ textAlign: 'center' }}>Your Reset Password Link is Invalid</h4>
                </div>
            }
          </div>
        </div>
      </div>
    </Fragment>
  );

}

export default Home;