/** Imports Starts Here ... */
import React, { Component, Fragment, useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route, useParams, useHistory } from "react-router-dom";
import 'react-rangeslider/lib/index.css';
import classnames from 'classnames';
import Header from "../../common/Header/Header";
import axios from "axios";
import { toast } from 'react-toastify';
import eye from '../../assets/images/eye.svg';
import { useForm } from "react-hook-form";
import helper from "../../helper/axiosHelper";

/** Function Component Starts Here ... */
const AccountActivation = (props) => {

     /** State Initialization */
     const history = useHistory();
     var id = useParams();
     id = id.activation_code;
     // var newId = id.replace('/activationpage/', '')
     const [activationStatus, setActivationStatus] = useState("inital")
     let baseUrl = helper.activationUrl();

     /** useEffect function to get data from an API & stores it on the setState Variables. */
     useEffect(() => {
          setTimeout(() => {
               axios.get(`${baseUrl}/${id}`).then((res) => {
                    if (res.data.status == 200) {
                         setActivationStatus(true);
                         toast.success(res.data.message)
                         setTimeout(() => {
                              history.push('/login');
                         }, 3000);
                    } else {
                         setActivationStatus(false);
                         toast.error(res.data.message)
                    }
               }).catch((err) => {
                    toast.error(err.data.message)
               })
          }, 3000);
     }, [])

     return (
          <Fragment>
               <Header />
               <div className="crPg">
                    <div className="container-fluid">
                         <div className="crPgCon">
                              <div className="crPgHd">
                                   <h1>Account Activation</h1>
                                   {
                                        activationStatus === 'initial' ?
                                             <> </>
                                             :
                                             activationStatus ?
                                                  <h4 style={{ color: '#fffff' }}>
                                                       Your account has been activated successfully.
                                                       Now you can login our site using your registered
                                                       credentails. If you have any quereies, Please
                                                       kindly contact our administrator or Collar Quest
                                                       technical teams.
                                                  </h4>
                                                  :
                                                  <h4 style={{ color: '#fffff' }}>
                                                       Your account activation is failed, because of
                                                       your account is already activated. If you have
                                                       any quereies, Please kindly contact our
                                                       administrator or Collar Quest technical teams.
                                                  </h4>
                                   }
                              </div>
                              <div className="crPgBd">

                              </div>
                         </div>
                    </div>
               </div>
          </Fragment>
     );

}

export default AccountActivation;