/** Import Starts Here ... */
import React, { Component, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import "./style.scss";
import Unity, { UnityContext } from "react-unity-webgl";

/// ! for redux
import { useDispatch, useSelector } from "react-redux";
import { walletAddress, addIndex, profilename, useremailid, enableLoader, balance, isWalletConnected, } from "../src/Action/userAction";
import ViewAccountActivation from './views/AccountActivation/AccountActivation';
import ViewReset from './views/Reset/Reset';

import helper from "../src/helper/axiosHelper";
import { io } from "socket.io-client";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";
import devtools from './Detect';

import LoadingOverlay from "react-loading-overlay-ts";
const ViewHome = React.lazy(() => import("./views/Home/Home"));
const ViewMarketplace = React.lazy(() => import("./views/Marketplace/Marketplace"));
const ViewMarketplacedet = React.lazy(() => import("./views/Marketplacedet/Marketplacedet"));
const ViewInventory = React.lazy(() => import("./views/Inventory/Inventory"));
const ViewClaimtokens = React.lazy(() => import("./views/Claimtokens/Claimtokens"));
const ViewDashboard = React.lazy(() => import("./views/Dashboard/Dashboard"));
const ViewNews = React.lazy(() => import("./views/News/News"));
const ViewMyaccount = React.lazy(() => import("./views/Myaccount/Myaccount"));
const ViewSettings = React.lazy(() => import("./views/Settings/Settings"));
const ViewScholarship = React.lazy(() => import("./views/Scholarship/Scholarship"));
const ViewScholarshipexplorer = React.lazy(() => import("./views/Scholarshipexplorer/Scholarshipexplorer"));
const ViewScholarshipexplorerdet = React.lazy(() => import("./views/Scholarshipexplorerdet/Scholarshipexplorerdet"));
const ViewActivity = React.lazy(() => import("./views/Activity/Activity"));
const ViewGame = React.lazy(() => import("./views/Game/game"));
const ViewFaq = React.lazy(() => import("./views/Faq/Faq"));
const ViewPrivacy = React.lazy(() => import("./views/Privacy/Privacy"));
const ViewTerms = React.lazy(() => import("./views/Terms/Terms"));
const ViewGlossery = React.lazy(() => import("./views/Glossery/Glossery"));
const ViewOurtoken = React.lazy(() => import("./views/Ourtoken/Ourtoken"));
const ViewOurtoken1 = React.lazy(() => import("./views/Ourtoken1/Ourtoken1"));
const ViewOurtoken2 = React.lazy(() => import("./views/Ourtoken2/Ourtoken2"));
const ViewOurtoken3 = React.lazy(() => import("./views/Ourtoken3/Ourtoken3"));
const ViewOurtoken4 = React.lazy(() => import("./views/Ourtoken4/Ourtoken4"));
const ViewOurtoken5 = React.lazy(() => import("./views/Ourtoken5/Ourtoken5"));
const ViewBattlingdetails = React.lazy(() => import("./views/Battlingdetails/Battlingdetails"));
const ViewBreedingdetails = React.lazy(() => import("./views/Breedingdetails/Breedingdetails"));
const ViewCodeofconduct = React.lazy(() => import("./views/Codeofconduct/Codeofconduct"));
const ViewGettingstarted = React.lazy(() => import("./views/Gettingstarted/Gettingstarted"));
const ViewAdventuredetails = React.lazy(() => import("./views/Adventuredetails/Adventuredetails"));

const ViewBorrow = React.lazy(() => import('./views/Borrow/Borrow'));
const ViewBorrowdet = React.lazy(() => import('./views/Borrowdet/Borrowdet'));
const ViewLogin = React.lazy(() => import('./views/Login/Login'));
const ViewRegister = React.lazy(() => import('./views/Register/Register'));
const ViewForget = React.lazy(() => import('./views/Forget/Forget'));
// const ViewReset = React.lazy(() => import('./views/Reset/Reset'));
// const ViewAccountActivation = React.lazy(() => import('./views/AccountActivation/AccountActivation'));
const ViewChangePassword = React.lazy(() => import('./views/Inventory/ChangePassword'));

const Web3 = require("web3");
const web3 = new Web3(window.ethereum);
const getProfileDetailsApi = helper.getProfileDetails();
const createProfileApi = helper.createProfile();

/** App Component Starts Here ... */
const App = (props) => {
  /** State Initialization. */
  var enableLoaderRedux = useSelector(
    (state) => state.userReducer.enableLoader
  );

  const dispatch = useDispatch();

  /** useEffect function to connect to Meta Mask with an Site. */
  useEffect(() => {
    if (localStorage.getItem("isWalletConnected") === "true") {
      connectingMetaMask();
    }
  }, []);

  /** useEffect function to page an reload when the account changed. */
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", () => {
        window.location.reload();
      });
    }
  }, []);

  useEffect(() => {
    const handleDevToolsChange = event => {
      if (event.detail.isOpen) {
        window.location.replace('https://www.google.com');
        setTimeout(() => { 
          window.close()
         }, 2000)
      }
    };

    window.addEventListener('devtoolschange', handleDevToolsChange);

    return () => {
      window.removeEventListener('devtoolschange', handleDevToolsChange);
    };
  }, [window]);

  /** Function to connect meta mask with an Wallet by using site.
    * @function getProfileDetails to get an profile details for an API.
   */
  const connectingMetaMask = async () => {
    if (typeof window.ethereum !== "undefined") {
      // Instance web3 with the provided information
      try {
        // Request account access
        await window.ethereum.enable();

        dispatch(enableLoader(true));
        const accounts = await web3.eth.getAccounts();
        dispatch(walletAddress(accounts[0]));
        dispatch(
          addIndex(accounts[0].slice(0, 5) + "....." + accounts[0].slice(-5))
        );
        dispatch(isWalletConnected(true));

        localStorage.setItem("accountNumber", accounts[0]);
        localStorage.setItem("isWalletConnected", true);
        getProfileDetails(accounts[0]);

        return true;
      } catch (e) {
        return false;
      }
    }
  };

  /** Function to get an profile details an the API to store data on redux function.
    * @function createProfile function to create a profile for an user.
    */
  const getProfileDetails = (data) => {
    helper
      .getData(getProfileDetailsApi + data, data)
      .then((res) => {
        if (res.data.status == true) {
          dispatch(enableLoader(false));

          dispatch(profilename(res.data.data.userName));
          dispatch(useremailid(res.data.data.email));
        } else {
          createProfile(data);
        }
      })
      .catch((err) => {
        dispatch(enableLoader(false));
      });
  };

  /** Function to create an profile details for an user while connecting account to site. */
  const createProfile = (acc) => {
    let data = {
      userName: "collarQuest User",
      email: "User@collarQuest.com",
      userAddress: acc,
    };
    helper
      .postData(createProfileApi, data)
      .then((res) => {
        if (res.data.status == true) {
          dispatch(enableLoader(false));

          dispatch(profilename("collarQuest User"));
          dispatch(useremailid("User@collarQuest.com"));
        } else {
          dispatch(enableLoader(false));
          toast.error(res.data.message);
        }
      })
      .catch((err) => dispatch(enableLoader(false)));
  };

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={ 5000 }
        hideProgressBar={ false }
        limit={ 2 }
        pauseOnHover
        theme="colored"
      />
      <Suspense fallback={ <div className="loading" /> }>
        <Router history={ props.history }>
          <LoadingOverlay
            active={ enableLoaderRedux }
            spinner
            text={ "Please Wait" }
          >
            <Switch>
              <Route path="/" exact render={ (props) => <ViewHome { ...props } /> } />
              <Route path="/marketplace" exact render={ (props) => <ViewMarketplace { ...props } /> } />
              <Route path="/marketplacedet" exact render={ (props) => <ViewMarketplacedet { ...props } /> } />
              <Route path="/inventory" exact render={ (props) => <ViewInventory { ...props } /> } />
              <Route path="/claimtokens" exact render={ (props) => <ViewClaimtokens { ...props } /> } />
              <Route path="/dashboard" exact render={ (props) => <ViewDashboard { ...props } /> } />
              <Route path="/news" exact render={ (props) => <ViewNews { ...props } /> } />
              <Route path="/myaccount" exact render={ (props) => <ViewMyaccount { ...props } /> } />
              <Route path="/settings" exact render={ (props) => <ViewChangePassword { ...props } /> } />
              <Route path="/scholarship" exact render={ (props) => <ViewScholarship { ...props } /> } />
              <Route path="/scholarshipexplorer" exact render={ (props) => <ViewScholarshipexplorer { ...props } /> } />
              <Route path="/scholarshipexplorerdet" exact render={ (props) => <ViewScholarshipexplorerdet { ...props } /> } />
              <Route path="/activity" exact render={ (props) => <ViewActivity { ...props } /> } />
              <Route path="/game" exact render={ (props) => <ViewGame { ...props } /> } />
              <Route path="/faq" exact render={ (props) => <ViewFaq { ...props } /> } />
              <Route path="/privacy" exact render={ (props) => <ViewPrivacy { ...props } /> } />
              <Route path="/terms" exact render={ (props) => <ViewTerms { ...props } /> } />
              <Route path="/glossery" exact render={ (props) => <ViewGlossery { ...props } /> } />
              <Route path="/ourtoken" exact render={ (props) => <ViewOurtoken { ...props } /> } />
              <Route path="/ourtoken1" exact render={ (props) => <ViewOurtoken1 { ...props } /> } />
              <Route path="/ourtoken2" exact render={ (props) => <ViewOurtoken2 { ...props } /> } />
              <Route path="/ourtoken3" exact render={ (props) => <ViewOurtoken3 { ...props } /> } />
              <Route path="/ourtoken4" exact render={ (props) => <ViewOurtoken4 { ...props } /> } />
              <Route path="/ourtoken5" exact render={ (props) => <ViewOurtoken5 { ...props } /> } />
              <Route path="/adventuredetails" exact render={ (props) => <ViewAdventuredetails { ...props } /> } />
              <Route path="/battlingdetails" exact render={ (props) => <ViewBattlingdetails { ...props } /> } />
              <Route path="/breedingdetails" exact render={ (props) => <ViewBreedingdetails { ...props } /> } />
              <Route path="/codeofconduct" exact render={ (props) => <ViewCodeofconduct { ...props } /> } />
              <Route path="/gettingstarted" exact render={ (props) => <ViewGettingstarted { ...props } /> } />

              {/* ===================== */ }
              <Route path="/borrow" exact render={ props => <ViewBorrow { ...props } /> } />
              <Route path="/borrowdet/:id" exact render={ props => <ViewBorrowdet { ...props } /> } />
              <Route path="/login" exact render={ props => <ViewLogin { ...props } /> } />
              <Route path="/register" exact render={ props => <ViewRegister { ...props } /> } />
              <Route path="/forget" exact render={ props => <ViewForget { ...props } /> } />
              <Route exact path="/auth/verify_user/:activation_code" component={ ViewAccountActivation } />
              <Route exact path="/auth/verify_user_reset/:reset_code" component={ ViewReset } />
              <Route path='/changePassword' exact render={ props => <ViewChangePassword { ...props } /> } />
            </Switch>
          </LoadingOverlay>
        </Router>
      </Suspense>
    </React.Fragment>
  );
};

export default App;
