/** export const action data to other component on Site. */
export const USER_INDEX = 'USER_INDEX';
export const ISWALLETCONNECTED = 'ISWALLETCONNECTED';
export const USER_ADDRESS = 'USER_ADDRESS';
export const GAMEWIN = 'GAMEWIN';
export const ENABLE_LOADER = 'ENABLE_LOADER';
export const PROFILENAME = 'PROFILENAME';
export const USERMAILID = 'USERMAILID';
export const BALANCE = 'BALANCE';
export const USERPROFILEIMAGE = "USERPROFILEIMAGE"
export const CHANGENAME = 'CHANGENAME';


export const SHOWFILTER = "SHOWFILTER"