/** Import starts here... */
import { USER_ADDRESS, ENABLE_LOADER, USER_INDEX, PROFILENAME, USERMAILID, BALANCE, ISWALLETCONNECTED, SHOWFILTER, USERPROFILEIMAGE, GAMEWIN, CHANGENAME } from "../constants/userConstants"

/** Export function to set an initial value for all redux variables. */
export function userReducer(state = {
    userAddress: "",
    enableLoader: false,
    USER_INDEX: "",
    profilename: "",
    useremailid: "",
    balance: "",
    isWalletConnected: "",
    showFilter: true,
    userprofileimage: "",
    gameWinning: false,
    nameChanged: "",
}
    , action) {


    switch (action.type) {

        case USER_ADDRESS: {

            return {
                ...state,
                userAddress: action.payload
            }
        }
        case USER_INDEX: {

            return {
                ...state,
                addIndex: action.payload
            }
        }
        case ENABLE_LOADER: {

            return {
                ...state,
                enableLoader: action.payload
            }
        }
        case PROFILENAME: {

            return {
                ...state,
                profilename: action.payload
            }
        }
        case USERMAILID: {

            return {
                ...state,
                useremailid: action.payload
            }
        }
        case USERPROFILEIMAGE: {

            return {
                ...state,
                userprofileimage: action.payload
            }
        }
        case BALANCE: {

            return {
                ...state,
                balance: action.payload
            }
        }
        case ISWALLETCONNECTED: {

            return {
                ...state,
                isWalletConnected: action.payload
            }
        }
        case SHOWFILTER: {

            return {
                ...state,
                showFilter: action.payload
            }
        }
        case GAMEWIN: {

            return {
                ...state,
                gameWinning: action.payload
            }
        }
        case CHANGENAME: {

            return {
                ...state,
                nameChanged: action.payload
            }
        }

        default:
            return state;
    }
}