/** Import Starts Here... */
import { USER_ADDRESS, ENABLE_LOADER, USER_INDEX, PROFILENAME, USERMAILID, BALANCE, ISWALLETCONNECTED, SHOWFILTER, USERPROFILEIMAGE, GAMEWIN, CHANGENAME } from "../constants/userConstants"

/** export function of Wallet Address data. */
export const walletAddress = (data) => ({
    type: USER_ADDRESS,
    payload: data
})

/** export function of loader data. */
export const enableLoader = (data) => ({
    type: ENABLE_LOADER,
    payload: data
})

/** export function of addIndex data. */
export const addIndex = (data) => ({
    type: USER_INDEX,
    payload: data
})

/** export function of profileName data. */
export const profilename = (data) => ({
    type: PROFILENAME,
    payload: data
})

/** export function of userEmailId data. */
export const useremailid = (data) => ({
    type: USERMAILID,
    payload: data
})

/** export function of Balance data. */
export const balance = (data) => ({
    type: BALANCE,
    payload: data
})

/** export function of isWalletConnected data. */
export const isWalletConnected = (data) => ({
    type: ISWALLETCONNECTED,
    payload: data
})

/** export function to show filtered data. */
export const filterShow = (data) => ({
    type: SHOWFILTER,
    payload: data
})

/** export function of user profile image data. */
export const userprofileimage = (data) => ({
    type: USERPROFILEIMAGE,
    payload: data
})


/** export function of user game winning details data. */
export const gameWinning = (data) => ({
    type: GAMEWIN,
    payload: data
})

/** export function of user name changed details data. */
export const nameChanged = (data) => ({
    type: CHANGENAME,
    payload: data
})