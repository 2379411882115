import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { userReducer } from "./Reducer/userReducer";
import thunk from "redux-thunk"


const reducer = combineReducers({
    userReducer: userReducer,
})

const initialState = {}
const middleware = [thunk];

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store;