/** Import Function starts here.... */
import axios from "axios";
import { toast } from "react-toastify";

/** Helper Function Starts Here.... */
const helper = {
  getOrderList: function () {
    const URL = "https://apiupdneds.collarquest.com/admin/token/getOrderList/";
    return URL;
  },
  getProfileDetails: function () {
    const URL = "https://apiupdneds.collarquest.com/admin/token/getProfileDetails/";
    return URL;
  },
  getTokenList: function () {
    const URL = "https://apiupdneds.collarquest.com/admin/token/getTokenList/";
    return URL;
  },
  getGameTokenList: function () {
    const URL = 'https://apiupdneds.collarquest.com/admin/token/getTokenAndUserNftData';
    return URL;
  },
  getSingleToken: function () {
    const URL = "https://apiupdneds.collarquest.com/admin/token/getSingleToken/";
    return URL;
  },
  createOrder: function () {
    const URL = "https://apiupdneds.collarquest.com/admin/token/createOrder";
    return URL;
  },
  createBreed: function () {
    const URL = "https://apiupdneds.collarquest.com/admin/token/createBreed";
    return URL;
  },
  createProfile: function () {
    const URL = "https://apiupdneds.collarquest.com/admin/token/createProfile";
    return URL;
  },
  history: function () {
    const URL = "https://apiupdneds.collarquest.com/admin/token/dashboard";
    return URL;
  },
  createActivity: function () {
    const URL = "https://apiupdneds.collarquest.com/user/activity/postactivity";
    return URL;
  },
  getActivity: function () {
    const URL = "https://apiupdneds.collarquest.com/user/activity/getuseractivity/";
    return URL;
  },
  updateProfile: function () {
    const URL = "https://apiupdneds.collarquest.com/admin/token/updateprofile";
    return URL;
  },
  getGameManager: function () {
    const URL = "https://apiupdneds.collarquest.com/admin/site/getgameManager";
    return URL;
  },
  postScholarShip: function () {
    const URL = "https://apiupdneds.collarquest.com/user/activity/scholarship";
    return URL;
  },
  getKey: function () {
    const URL = "https://apiupdneds.collarquest.com/admin/basic/getCheckdata";
    return URL;
  },
  postRewardUpdate: function () {
    const URL = "https://apiupdneds.collarquest.com/admin/token/rewardUpdatePost";
    return URL;
  },
  getRewards: function () {
    const URL = "https://apiupdneds.collarquest.com/admin/token/rewardUpdateGet";
    return URL;
  },
  // ! ==============
  registerUrl: function () {
    const URL = "https://apiupdneds.collarquest.com/users/register";
    return URL;
  },
  activationUrl: function () {
    const URL = 'https://apiupdneds.collarquest.com/users/verify-user';
    return URL;
  },
  loginUrl: function () {
    const URL = 'https://apiupdneds.collarquest.com/users/login';
    return URL;
  },
  forgetUrl: function () {
    const URL = 'https://apiupdneds.collarquest.com/users/forget-password';
    return URL;
  },
  forgetVerifyUrl: function () {
    const URL = 'https://apiupdneds.collarquest.com/users/verify-forget-password';
    return URL;
  },
  resetUrl: function () {
    const URL = 'https://apiupdneds.collarquest.com/users/reset-password';
    return URL;
  },
  getUserUrl: function () {
    const URL = "https://apiupdneds.collarquest.com/users/user";
    return URL;
  },
  postUserUrl: function () {
    const URL = 'https://apiupdneds.collarquest.com/users/updateUserProfile';
    return URL;
  },
  imageUpload: function () {
    const URL = 'https://apiupdneds.collarquest.com/users/imageUpload';
    return URL;
  },
  changePasswordUrl: function () {
    const URL = 'https://apiupdneds.collarquest.com/users/changePasswordUser';
    return URL;
  },
  nftOnEachUser: function () {
    const URL = "https://apiupdneds.collarquest.com/users/token-data";
    return URL;
  },
  nftRentingURL: function () {
    const URL = "https://apiupdneds.collarquest.com/users/manager-lend-data";
    return URL;
  },
  rentedNftURl: function () {
    const URL = "https://apiupdneds.collarquest.com/users/find_manager-lend-data";
    return URL;
  },
  singleNFTDataURL: function () {
    const URL = "https://apiupdneds.collarquest.com/users/single-manager-lend-data";
    return URL;
  },
  nftRemoveUrl: function () {
    const URL = "https://apiupdneds.collarquest.com/users/removeManagerLendNft";
    return URL;
  },
  userNftRentingURL: function () {
    const URL = "https://apiupdneds.collarquest.com/users/user-lend-nft";
    return URL;
  },
  userRentedNftListInventory: function () {
    const URL = "https://apiupdneds.collarquest.com/users/singleUserLendNft";
    return URL;
  },
  userDetailsAll: function () {
    const URL = "https://apiupdneds.collarquest.com/users/getAllLendNft";
    return URL;
  },
  userDetailsMarket: function () {
    const URL = "https://apiupdneds.collarquest.com/admin/token/nftTransferHistory";
    return URL
  },
  statusUpdaterUrl: function () {
    const URL = "https://apiupdneds.collarquest.com/users/buyManagerLendNft";
    return URL;
  },
  duration: function () {
    const URL = 'https://apiupdneds.collarquest.com/admin/durationStatus';
    return URL;
  },
  getDuration: function () {
    const URL = 'https://apiupdneds.collarquest.com/admin/duration';
    return URL;
  },
  getSiteDuartion: function () {
    const URL = "https://apiupdneds.collarquest.com/admin/getsite-duration";
    return URL;
  },
  getToken: function () {
    const URL = 'https://apiupdneds.collarquest.com/admin/tokenAddressStatus';
    return URL;
  },
  getTokenUrl: function () {
    const URL = 'https://apiupdneds.collarquest.com/admin/tokenAddress';
    return URL;
  },
  // ! ==============
  /** Post Data Function to hit API from another Component.
    * use @params as a url, payload data & works on axiosConfig function.
    * @returns response as res.
    */
  postData: async function (url, data) {
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "true",
        "authorization": "Bearer " + localStorage.getItem('authToken'),
      },
    };
    return await axios .post(url, data, axiosConfig) .then((res) => {
        if(res?.data?.status === 429) {
          return toast.error(res?.data?.message)
        } else {
          return res;
        }
      })
      .catch((err) => {
        return err;
      });
  },

  /** get Data Function to hit API from another Component.
    * use @params as a url & works on axiosConfig function.
    * @returns response as res.
    */
  getData: async function (url, data) {
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "true",
        "authorization": "Bearer " + localStorage.getItem('authToken'),
      },
    };
    return await axios.get(url, axiosConfig).then((res) => {
      if(res?.data?.status === 429) {
        return toast.error(res?.data?.message)
      } else {
        return res;
      }
    });
  },
};

export default helper;
